* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /*remove blue highlight*/
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Inter', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 100%;
  transition: all 0.5s ease-in;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
  min-height: 100vh;
  padding-left: 10px;
  padding-right: 10px;
}

.css-ucvbz3-MuiPaper-root-MuiDialog-paper {
  width: 555px;
  height: auto;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 10px;
  padding-bottom: 0px;
}

.css-1t4vnk2-MuiDialogContent-root {
  border: none;
}

.css-hz1bth-MuiDialog-container {
  background-color: #afb5b3;
}
@font-face {
  font-family: 'Inter Regular';
  font-style: normal;
  src: url(../src/assets/fonts/Inter-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto Regular';
  font-style: normal;
  src: url(../src/assets/fonts/Roboto-Regular.ttf) format('truetype');
}
